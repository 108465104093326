export default {
  blogerName: 'GODWINS',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/godwins'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@HunterGodbr/streams'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/edson.god/?hl=pt-br'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/godesports/?hl=pt-br'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/c3bbf45a9',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irit01.com/cbdb5403b',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-ircp12.com/c82e74153',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irit01.com/c6296af4a',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2b3d1d6f',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cf0ee0f77',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c7326b635',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/c6aab003e',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ca8f6ac39',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>GODWINS</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'GODWINS',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
